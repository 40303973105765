import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import useTiposApoyo from '../hooks/useTiposApoyo'
import useDependencias from './DependenciasComponent'
import { ObtenerDatos } from '../helpers/ApiHelper'
import Select from 'react-select';
import { Expand, ExpandCircleDown, ImageSearch } from '@mui/icons-material'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import useDependenciasUsuario from './DependenciasUsuario'

export const ModalEntregaApoyo = () => {
    const {modalEntregaApoyo, setModalEntregaApoyo, beneficiarioSeleccionado, usuario} = useContext(AuthContext)
    const {tipoApoyoSeleccionado, setTipoApoyoSeleccionado, ComboTiposApoyo, obtenerTiposApoyo} = useTiposApoyo();
    const {dependencias, ComboDependencias, dependenciaSeleccionada, setSuDependencia} = useDependenciasUsuario();
    const [historialApoyo, setHistorialApoyo] = useState()
    const [autorizan, setAutorizan] = useState();
    const uploadInputRef = useRef(null);
    const [file, setFile] = useState()
    const [archivos, setArchivos] = useState([])
    const [datosApoyo, setDatosApoyo] = useState({
        idDependencia:dependenciaSeleccionada?.value || 0,
        idTipoApoyo:0,
        idUsuarioAutoriza:usuario?.Usuarios_IdUsuario,
        fechaEntrega:dayjs().format("YYYY-MM-DD HH:mm:ss"),
        comentarios:''
    })
    const [idAutorizo, setIdAutorizo] = useState()
    const [deshabilitaGuardar, setDeshabilitaGuardar] = useState(true)

    const handleClose = () => {
        setDatosApoyo({
           idDependencia:usuario?.idDependencia,
            idTipoApoyo:0,
            idUsuarioAutoriza:usuario?.Usuarios_IdUsuario,
            fechaEntrega:dayjs().format("YYYY-MM-DD HH:mm:ss"),
            comentarios:''
        })
        setFile();
        setTipoApoyoSeleccionado()
        setHistorialApoyo()
        setModalEntregaApoyo(false)
    }

    useEffect(() => {
//        actualizaDatos('idDependencia',dependenciaSeleccionada)
    }, [dependenciaSeleccionada])

    
    

    useEffect(() => {
        console.log('selecciono dependencia', dependenciaSeleccionada)
        obtenerTiposApoyo(dependenciaSeleccionada?.value || 0);
      }, [dependenciaSeleccionada])

      const obtenerAutorizan = () => {
        const data = new FormData()
        data.append('idUsuario', usuario?.Usuarios_IdUsuario)
        ObtenerDatos('/usuarios.php?funcion=autorizanApoyos', data).then(response => {
            console.log('respuesta del servidor', response)
            let opciones = new Array();
            response?.map(autoriza => {
                opciones.push({value:autoriza?.Usuarios_IdUsuario, label:autoriza?.nombreCompleto})
            })
            setAutorizan(opciones)
        })
      }

      useEffect(() => {
        setSuDependencia(true)
        obtenerAutorizan();
      }, [])

      useEffect(() => {
        if(modalEntregaApoyo) obtenerAutorizan() ;
      }, [modalEntregaApoyo])
      
      const SeleccionImagen = (event) => {
        setFile(event.target.files);
        console.log('selecciono imagen',event);
        var reader = new FileReader();
//        reader.onload = function(){
//            imgMilitante.current.src = reader.result;
 //       };
 //       reader.readAsDataURL(event.target.files[0]);
    };

    useEffect(() => {
        console.log('Archivos seleccionados', file)
     //   console.log('esun arreglo', file[0].name)
        let lista = new Array();
        for(let i=0; i<file?.length;i++) {
            console.log(file[i].name)
            lista.push(file[i].name)
        }
        setArchivos(lista)
    }, [file])

    const actualizaDatos = (campo, valor) => {
        setDatosApoyo({
            ...datosApoyo,
            [campo]:valor
        })
    }

    useEffect(() => {
        actualizaDatos('idDependencia', dependenciaSeleccionada?.value)
    }, [dependenciaSeleccionada])

    useEffect(() => {
        actualizaDatos('idTipoApoyo', tipoApoyoSeleccionado?.value)
        buscarHistorialApoyo()
    }, [tipoApoyoSeleccionado])

    useEffect(() => {
        actualizaDatos('idUsuarioAutoriza', idAutorizo?.value)
    }, [idAutorizo])
    
    const buscarHistorialApoyo = () => {
        const data = new FormData()
        data.append('idBeneficiario', beneficiarioSeleccionado?.idBeneficiario)
        data.append('idTipoApoyo', tipoApoyoSeleccionado?.value)
        ObtenerDatos('/apoyos.php?funcion=historialApoyo', data).then(response => {
            console.log('respuesta historial apoyo', response)
            setHistorialApoyo(response)
        })
    }
    

    useEffect(() => {
        console.log('datos de apoyo', datosApoyo)
    }, [datosApoyo])
    
    const guardarApoyo = () => {
        const data = new FormData();
        data.append('datos',JSON.stringify(datosApoyo))
        data.append('idBeneficiario', beneficiarioSeleccionado?.idBeneficiario)
        data.append('archivos', uploadInputRef.current?.files?.length || 0)
        data.append('idUsuario', usuario?.Usuarios_IdUsuario)

        for(let x=0;x<uploadInputRef?.current?.files.length;x++) {
            console.log('archivo', uploadInputRef.current?.files[x])
            data.append('archivo'+x, uploadInputRef.current?.files[x]);
        }

        ObtenerDatos('/apoyos.php?funcion=guardarApoyo', data).then(response => {
            console.log('respuesta de guardar apoyo', response)
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Apoyo guardado correctamente",
                showConfirmButton: false,
                timer: 1500
              });
              setDeshabilitaGuardar(false)
              setDatosApoyo({
                  idDependencia:0,
                  idTipoApoyo:0,
                  idUsuarioAutoriza:0,
                  fechaEntrega:dayjs().format("YYYY-MM-DD HH:mm:ss"),
                  comentarios:''
              })
  
            setModalEntregaApoyo(false)
        })
    }

    useEffect(() => {
        console.log('datos de apoyo', datosApoyo)
        validaDatos()
    }, [datosApoyo])

    useEffect(() => {
        validaDatos()
    }, [beneficiarioSeleccionado])

    const validaDatos = () => {
        console.log('validando datos', dependenciaSeleccionada, tipoApoyoSeleccionado)
        if(datosApoyo?.idDependencia==0) {
            actualizaDatos('idDependencia', dependenciaSeleccionada?.value)
        }
        if(datosApoyo?.idTipoApoyo==0) {
            actualizaDatos('idTipoApoyo', tipoApoyoSeleccionado?.value)
        }
        if(datosApoyo?.idDependencia>0 && datosApoyo?.idTipoApoyo>0 &&  datosApoyo?.comentarios?.length>0 && beneficiarioSeleccionado?.idBeneficiario) {
            setDeshabilitaGuardar(false)
        } else {
            setDeshabilitaGuardar(true)

        }
    }
    

  return (
    <Dialog
        size='md'
        fullWidth
        open={modalEntregaApoyo}
        onClose={() => {handleClose()}}
    >
        <DialogContent>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column'
                }}
            >
                <ComboDependencias />
                <div style={{marginTop:10}}>
                    <ComboTiposApoyo />
                </div>
                {
                    historialApoyo?.length ? (
                <div
                    style={{
                        marginTop:10,
                        marginBottom:10,
                    }}
                >
                    <Accordion
                        style={{
                            backgroundColor:'#B3282D',
                            borderRadius:10

                        }}
                    >
                        <AccordionSummary
                        expandIcon={<ExpandCircleDown color='white' style={{backgroundColor:'#B3282D', color:'white'}} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        
                        >
                        <Typography color='white' fontSize={12} component="span">El apoyo ha sido entregado {historialApoyo?.length} {historialApoyo?.length==1 ? 'vez':'veces'} a este beneficiario</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                                <div
                                style={{
                                    display:'flex',
                                    flexDirection:'row',
                                    backgroundColor:'white',
                                    color:'#B3282D',
                                    borderRadius:10,
                                    padding:10,
                                    fontWeight:600
                                }}
                            >
                                <Typography style={{display:'flex', flex:5, fontSize:10}}>Fecha</Typography>
                                <Typography style={{display:'flex', flex:5, fontSize:10}}>Entregó</Typography>
                                <Typography style={{display:'flex', flex:10, fontSize:10}}>Apoyo</Typography>
                                <Typography style={{display:'flex', flex:10, fontSize:10}}>Comentarios</Typography>
                            </div>
{
                                historialApoyo?.map(apoyo => (
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            backgroundColor:'white',
                                            color:'black',
                                            borderRadius:10,
                                            padding:5,
                                            fontWeight:600,
                                            fontSize:10,
                                            alignItems:'center',
                                            marginTop:3
                                        }}
                                    >
            
                                                <Typography style={{display:'flex', flex:5, fontSize:10}}>{dayjs(apoyo?.fecha).format('DD/MM/YYYY')}</Typography>
                                                <Typography style={{display:'flex', flex:5, fontSize:10}}>{apoyo?.usuarioEntrega}</Typography>
                                                <Typography style={{display:'flex', flex:10, fontSize:10}}>{apoyo?.tipoApoyo}</Typography>
                                                <Typography style={{display:'flex', flex:10, fontSize:10}}>{apoyo?.comentarios}</Typography>
                                                {/* <Typography style={{display:'flex', flex:2, fontSize:12}}>{apoyo?.imagenes?.length ? (
                                                    <IconButton
                                                        onClick={()=>{setApoyoSeleccionado(apoyo);setModalImagenApoyo(true)}}
                                                    >
                                                        <CameraAlt style={{color:'green'}} />
                                                    </IconButton>)
                                                    :
                                                    (<IconButton><CameraAlt style={{color:'gray'}} /></IconButton>)}</Typography>
                             */}
                                    </div>
                                    ))
                                
                                }
                        </AccordionDetails>
                    </Accordion>
                </div>
                    ):null
                }
                {/* <Select
                    onChange={(e) => {setIdAutorizo(e)}}
                   // value={tipoApoyoSeleccionado}
                    options={autorizan}
                    placeholder="Autorizó"
                    menuPortalTarget={document.body} 
                    InputLabelProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    InputProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize:11 }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'grey' : 'green',
                            fontSize:12,
                            minWidth:150,
                            maxHeight:20,
                            borderRadius:10,
                            marginTop:10
                        }),
                    }}
                    style={{
                        width:150,
                        maxHeight:20,
                        marginTop:10,
                        menuPortal: base => ({ ...base, zIndex: 999999, fontSize:11 }),
        
                    }}
                />                 */}
                {/* <TextField 
                    onChange={(e) => {actualizaDatos('fechaEntrega', e.target.value)}}
//                    value={datosBeneficiario?.fechaNacimiento}
                    
                    size='small'
                    label="Fecha de entrega"
                    type='date'
                    required
                    style={{
                        marginTop:10

                    }}
                    InputLabelProps={{
                        shrink:true,
                    }}
                    
                />
 */}
                    
            </div>
            <div>
                <TextField
                    onChange={(e) => {actualizaDatos('comentarios', e.target.value)}}
                    label="Comentarios"
                    type='text'
                    multiline
                    rows={2}
                    fullWidth
                    style={{
                        marginTop:10
                    }}
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'row'
                }}
            >
                <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" multiple />
                <Button
                    style={{
                    textTransform:'unset',
                    maxWidth:140,
                    minWidth:'40%',
                    marginTop:20,
                    backgroundColor:'#B3282D'
                    }}
                    variant="contained"
                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    >Comprobante de Entrega <ImageSearch /></Button>
            </div>
            <div>
                {
                    archivos?.map(archivo => (
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'column',
                                fontSize:12
                            }}
                        >
                            {archivo}
                        </div>
                    ))
                }
            </div>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {handleClose()}}
                style={{textTransform:'unset', color:'black'}}
            >
                Cancelar    
            </Button>
            <Button
                onClick={() => {guardarApoyo()}}
                disabled={deshabilitaGuardar}
                style={{textTransform:'unset', color: deshabilitaGuardar ? 'gray':'black'}}
            >
                Guardar
            </Button>
        </DialogActions>
    </Dialog>
  )
}