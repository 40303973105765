import React, { useContext, useEffect, useRef, useState } from 'react'
import { UsuarioComponent } from '../components/UsuarioComponent'
import logoEncabezado from '../assets/logoEncabezado.png'
import { Badge, Button, Divider, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Pagination, TextField, Typography } from '@mui/material'
import { AccountCircle, ArrowDropDown, ArrowDropUp, Camera, CameraAlt, CheckCircle, Close, Edit, ImageSearch, MaleOutlined, Man2Outlined, Person, PersonPinCircle, Phone, Photo, Room, Search, SetMealRounded, TransgenderOutlined, Woman2Outlined } from '@mui/icons-material'
//import useDependencias from '../components/DependenciasComponent'
import useTiposApoyo from '../hooks/useTiposApoyo'
import { ObtenerBeneficiarios } from '../helpers/BeneficiariosHelper'
import dayjs from 'dayjs'
import 'dayjs/locale/es';

import { AuthContext } from '../context/AuthContext'
import { ModalAgregarBeneficiario } from '../components/ModalAgregarBeneficiario'
import useGeneros from '../components/GenerosComponent'
import Swal from 'sweetalert2'
import { ObtenerDatos } from '../helpers/ApiHelper'
import { ModalEntregaApoyo } from '../components/ModalEntregaApoyo'
import { ModalApoyosBrindados } from '../components/ModalApoyosBrindados'
import { ModalFichaRub } from '../components/ModalFichaRub'
import useDependenciasUsuario from '../components/DependenciasUsuario'

dayjs.locale('es')

export const BeneficiariosPage = () => {
  const {dependencias, ComboDependencias, dependenciaSeleccionada} = useDependenciasUsuario();
  const {tipoApoyoSeleccionado, ComboTiposApoyo, obtenerTiposApoyo} = useTiposApoyo();
  const {generoSeleccionado, setGeneroSeleccionado, ComboGeneros} = useGeneros()
  
  const {beneficiarioSeleccionado, setBeneficiarioSeleccionado, setModalAgregaBeneficiario, setModalEntregaApoyo, modalEntregaApoyo, modalApoyosBrindados, setModalApoyosBrindados, modalFichaRub, setModalFichaRub, usuario, paginaBeneficiarios, setPaginaBeneficiarios, ordenBeneficiarios, setOrdenBeneficiarios} = useContext(AuthContext)
  const [inicio, setInicio] = useState()
  const [fin, setFin] = useState()
  const [beneficiarios, setBeneficiarios] = useState()
  const [datos, setDatos] = useState({
    'accion1':'',
    'hombres':true,
    'mujeres':true,
    'trans':true,
    'telefono':false,
    'idDependencia':0,
    'idTipoApoyo':0,
    'inicio':dayjs().format('YYYY-MM-DD'),
    'final2':dayjs().format('YYYY-MM-DD'),
    'pagina':1
  });
  const [busqueda, setBusqueda] = useState('')

  const uploadInputRef = useRef(null);
  const [file, setFile] = useState()
  const [archivos, setArchivos] = useState([])
  const uploadInputRef1 = useRef(null);
  const [file1, setFile1] = useState()
  const [archivos1, setArchivos1] = useState([])
  const uploadInputRef2 = useRef(null);
  const [file2, setFile2] = useState()
  const [archivos2, setArchivos2] = useState([])
  const [editando, setEditando] = useState(false)


  const SeleccionImagen = (event) => {
    setFile(event.target.files);
    console.log('selecciono imagen',event);
    var reader = new FileReader();
//        reader.onload = function(){
//            imgMilitante.current.src = reader.result;
//       };
//       reader.readAsDataURL(event.target.files[0]);
};
  const SeleccionImagen1= (event) => {
    setFile1(event.target.files);
    console.log('selecciono imagen',event);
    var reader = new FileReader();
//        reader.onload = function(){
//            imgMilitante.current.src = reader.result;
//       };
//       reader.readAsDataURL(event.target.files[0]);
};
  const SeleccionImagen2 = (event) => {
    setFile2(event.target.files);
    console.log('selecciono imagen',event);
    var reader = new FileReader();
//        reader.onload = function(){
//            imgMilitante.current.src = reader.result;
//       };
//       reader.readAsDataURL(event.target.files[0]);
};
  useEffect(() => {
    console.log('Archivos seleccionados', file)
 //   console.log('esun arreglo', file[0].name)
    let lista = new Array();
    for(let i=0; i<file?.length;i++) {
        console.log(file[i].name)
        lista.push(file[i].name)
    }
    setArchivos(lista)
}, [file])

  useEffect(() => {
    console.log('Archivos seleccionados', file1)
 //   console.log('esun arreglo', file[0].name)
    let lista = new Array();
    for(let i=0; i<file2?.length;i++) {
        console.log(file1[i].name)
        lista.push(file1[i].name)
    }
    setArchivos1(lista)
}, [file1])

  useEffect(() => {
    console.log('Archivos seleccionados', file2)
 //   console.log('esun arreglo', file[0].name)
    let lista = new Array();
    for(let i=0; i<file2?.length;i++) {
        console.log(file2[i].name)
        lista.push(file2[i].name)
    }
    setArchivos2(lista)
}, [file2])

useEffect(() => {
  if(uploadInputRef.current?.files.length>0) {
  const data = new FormData();
  data.append('idBeneficiario', beneficiarioSeleccionado?.idBeneficiario)
  data.append('archivo', uploadInputRef.current?.files[0]);
  ObtenerDatos('/beneficiarios.php?funcion=subirFoto', data).then(response => {
    console.log('respuesta de subir foto', response)
    if(response!='0') {
      console.log('se subio la foto correctamente')
      setBeneficiarioSeleccionado({
        ...beneficiarioSeleccionado,
        'foto':'https://rubjrz.com/imagenes/beneficiarios/'+response
      })
    }
  })
}
}, [archivos])

useEffect(() => {
  if(uploadInputRef1.current?.files.length>0) {
  const data = new FormData();
  data.append('idBeneficiario', beneficiarioSeleccionado?.idBeneficiario)
  data.append('archivo', uploadInputRef1.current?.files[0]);
  ObtenerDatos('/beneficiarios.php?funcion=subirFotoAnverso', data).then(response => {
    console.log('respuesta de subir foto', response)
    if(response!='0') {
      console.log('se subio la foto correctamente')
      setBeneficiarioSeleccionado({
        ...beneficiarioSeleccionado,
        'idAnverso':'https://rubjrz.com/imagenes/identificaciones/'+response
      })
    }
  })
}
}, [archivos1])

useEffect(() => {
  if(uploadInputRef2.current?.files.length>0) {
  const data = new FormData();
  data.append('idBeneficiario', beneficiarioSeleccionado?.idBeneficiario)
  data.append('archivo', uploadInputRef2.current?.files[0]);
  ObtenerDatos('/beneficiarios.php?funcion=subirFotoReverso', data).then(response => {
    console.log('respuesta de subir foto', response)
    if(response!='0') {
      console.log('se subio la foto correctamente')
      setBeneficiarioSeleccionado({
        ...beneficiarioSeleccionado,
        'idReverso':'https://rubjrz.com/imagenes/identificaciones/'+response
      })
    }
  })
}
}, [archivos2])


  useEffect(() => {
    console.log('selecciono dependencia', dependenciaSeleccionada)
    obtenerTiposApoyo(dependenciaSeleccionada?.value || 0);
  }, [dependenciaSeleccionada])

  const actualizaDatos = async (campo, valor) => {
    console.log(campo, valor)
    await setDatos({
      ...datos,
      [campo]:valor
    })
    await setBeneficiarioSeleccionado({
      ...beneficiarioSeleccionado,
      'accion':'editar'
    })
    if(valor=='editar') {
      await setModalAgregaBeneficiario(true);
    } 
  }

  const editar = async(beneficiario) => {
    console.log('se va a editar', beneficiario)
    await setBeneficiarioSeleccionado(beneficiario); 
  //  await actualizaDatos('accion','editar');
    await setEditando(true)
  }


  useEffect(() => {
    console.log('editando', editando)
    if(editando) {
      actualizaDatos('accion','editar')
      setEditando(false)
    }
  }, [editando])
  

  useEffect(() => {
    console.log(datos)
  }, [datos])

  useEffect(() => {
    console.log(dependenciaSeleccionada)
    actualizaDatos('idDependencia', dependenciaSeleccionada?.value || 0)
  }, [dependenciaSeleccionada])
  
  useEffect(() => {
    console.log('tipo de apoyo seleccionado',tipoApoyoSeleccionado)
    actualizaDatos('idTipoApoyo', tipoApoyoSeleccionado?.value || 0)
  }, [tipoApoyoSeleccionado])

  useEffect(() => {
    console.log('inicio', inicio)
    actualizaDatos('inicio',inicio?.target?.value)
  }, [inicio])
  
  useEffect(() => {
    console.log('fin', fin)
    actualizaDatos('final',fin?.target?.value)
  }, [fin])
  
    const obtenerBeneficiarios = () => {
      if(usuario?.permisos?.substr(8,1)==1) {

      ObtenerBeneficiarios(datos, paginaBeneficiarios, ordenBeneficiarios).then(response => {
        console.log('beneficiarios obtenidos',response, paginaBeneficiarios)
        
        setBeneficiarios(response)
      })
    }
    }

    useEffect(() => {
      if(!modalEntregaApoyo) obtenerBeneficiarios();
    }, [modalEntregaApoyo])
    

    useEffect(() => {
      console.log('genero seleccionado', generoSeleccionado);
    }, [generoSeleccionado])

    useEffect(() => {
      console.log('beneficiario seleccionado', beneficiarioSeleccionado)
    }, [beneficiarioSeleccionado])

    const buscarBeneficiario =() => {
      const data = new FormData();
      data.append('busqueda', busqueda)
      ObtenerDatos('/beneficiarios.php?funcion=buscarBeneficiario', data).then(response => {
        console.log('resultados de la busqueda', response)
        setBeneficiarios(response)
      })
    }

    useEffect(() => {
      if(!beneficiarioSeleccionado?.nombre1) {
        console.log('beneficiarios para select', beneficiarios)
        if(beneficiarios?.beneficiarios?.length>0) {
          setBeneficiarioSeleccionado(beneficiarios?.beneficiarios[0])
        }
      }

    }, [beneficiarios])

    useEffect(() => {
      console.log('selecciono beneficiario', beneficiarioSeleccionado)
    }, [beneficiarioSeleccionado])
    
    

    const exportar = () => {
      console.log('Beneficiarios a exportar', beneficiarios?.beneficiarios)
      let exportados = new Array();
      console.log('datos exportando', exportados)
      let data = new FormData();
      //datos, paginaBeneficiarios, ordenBeneficiarios
      data.append('datos', JSON.stringify(datos))
      data.append('paginaBeneficiarios', paginaBeneficiarios)
      data.append('ordenBeneficiarios', ordenBeneficiarios)
      const params = new URLSearchParams((data));
      window.open('https://rubjrz.com/servicios/exportar.reportes1.php?'+params)
      

    }

   
    
    

    const eliminarBeneficiario = (idBeneficiario) => {
      Swal.fire({
        title: '¿Estás seguro(a)?',
        text: "Se eliminará el beneficiario, esto no puede ser revertido",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          const data = new FormData();
          data.append('idBeneficiario', idBeneficiario)
          ObtenerDatos('/beneficiarios.php?funcion=eliminarBeneficiario', data).then(response => {
            if(response==1) {
              Swal.fire(
                'Eliminado!',
                'El beneficiario ha sido eliminado.',
                'success'
              )
              obtenerBeneficiarios();
            } else {
              Swal.fire(
                'Error!',
                'el beneficiario no pudo ser eliminado!.',
                'error'
              )
  
            }
          })
        }
      })
    }

    const cambioPaginador = (e, value) => {
      console.log('cambio en paginador', e, value)
      setPaginaBeneficiarios(value)
    }

    useEffect(() => {
      console.log('cambio la pagina, obteniendo beneficiarios', paginaBeneficiarios)
      obtenerBeneficiarios();
    }, [paginaBeneficiarios])
    

    useEffect(() => {
      console.log('cambioel orden de beneficiarios', ordenBeneficiarios)
      obtenerBeneficiarios()
    }, [ordenBeneficiarios])
    

  return (
    <>
    <div
    style={{
      display:'flex',
      flex:1,
      flexDirection:'column',
      marginLeft:20
    }}
  >
    <div
        style={{
          display:'flex',
          flexDirection:'row',
          flex:10,
          justifyContent:'space-between',
          marginTop:20,
          marginRight:20, maxHeight:32,
          alignItems:'center'
        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center'
          }}
        >
     <TextField
      label="Buscar beneficiario por Nombres, #RUB ó CURP"
      onChange={(e) => {setBusqueda(e.target.value)}}
      InputProps={{
        shrink:true,
        style:{
          backgroundColor:'white',
          borderRadius:10,
          fontSize:12,
          minWidth:500,
          maxHeight:35
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
    <Button
      onClick={() => {buscarBeneficiario()}}
      variant='contained'
      style={{
        backgroundColor:'#B3282D',
        textTransform:'unset',
        paddingLeft:40,
        paddingRight:40,
        maxHeight:30,
        borderRadius:15,
        marginLeft:30
      }}
    >
      <Typography>
        Buscar
      </Typography>
    </Button>
    </div>
        <div
          style={{
            display:'flex',
            flexDirection:'row'
            
          }}
        >
        <img
            style={{
                width:250,
                height:'auto',
                
//                marginTop:'15%'
            }}
            src={logoEncabezado}
        />
        <UsuarioComponent /> 
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'column',
          flex:1,
          backgroundColor:'transparent',
          marginLeft:0,
          marginTop:20,
          marginBottom:20,
          borderRadius:15    
        }}
      >
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:20,
          backgroundColor:'#B3282D',
          paddingTop:5,
          paddingBottom:5,
          paddingLeft:20,
          borderTopLeftRadius:15,
          borderTopRightRadius:15,
          marginRight:20,
          alignItems:'center',
          justifyContent:'space-between'
        }}
      >
        <div
          style={{
            maxWidth:160
          }}
        >
      <ComboDependencias
        style={{
          width:100
        }}
      />
      </div>
        <div
          style={{
            maxWidth:90,
         //   marginLeft:10
          }}
        >
      <ComboTiposApoyo />
      </div>
      <div
              style={{
                backgroundColor:'transparent',
                minWidth:120,
                marginLeft:70,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                alignItems:'center'
              }}
            >
              <Typography
              style={{
                color:'white',
                fontSize:12
              }}
            >
              De
            </Typography>
              <TextField
                type='date'
                size='small'
                onChange={(e) => {setInicio(e)}}
        //        value={dayjs(inicio).format('YYYY-MM-DD')}
                inputProps={{
                  style:{
                    fontSize:12
                  }
                }}
                style={{
                  backgroundColor:'white',
                  borderRadius:15,
                  marginLeft:10,
                  width:140
                }}
              />
            </div>
            <div
              style={{
                backgroundColor:'transparent',
                minWidth:150,
                marginLeft:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row'
              }}
            ><Typography
              style={{
                color:'white',
                fontSize:12
              }}
            >
              Hasta
            </Typography>
              <TextField
           //     value={dayjs(fin).format('YYYY-MM-DD')}
                type='date'
                size='small'
                onChange={(e) => {setFin(e)}}
                inputProps={{
                  style: {
                    fontSize:11

                  }
                }}
                style={{
                  backgroundColor:'white',
                  borderRadius:15,
                 // marginLeft:10,
                  width:140,
                  fontSize:12
                }}
              />
              </div>      
              <Button
                variant='contained'
                size='small'
                onClick={() => {actualizaDatos('hombres', !datos.hombres)}}

                style={{
                  backgroundColor:'white',
                  maxHeight:'80%',
                  //marginLeft:20,
                  maxWidth:10
                }}
              >
                <Man2Outlined style={{color:'black'}} />
                {
                  datos?.hombres ? (
                <CheckCircle
                  style={{
                    position:'absolute',
                    right:-5,
                    bottom:-5,
                    color:'#00BA00'
                  }}
                />
                  ):null
                }
              </Button>
              <Button
                variant='contained'
                size='small'
                onClick={() => {actualizaDatos('mujeres', !datos.mujeres)}}

                style={{
                  backgroundColor:'white',
                  maxHeight:'80%',
                //  marginLeft:20
                }}
              >
                <Woman2Outlined style={{color:'black'}} />
                {
                  datos.mujeres ? (
                <CheckCircle
                  style={{
                    position:'absolute',
                    right:-5,
                    bottom:-5,
                    color:'#00BA00'
                  }}
                />
                  ):null
              }
              </Button>
              <Button
                variant='contained'
                size='small'
                onClick={() => {actualizaDatos('trans', !datos.trans)}}

                style={{
                  backgroundColor:'white',
                  maxHeight:'80%',
               //   marginLeft:20
                }}
              >
                <TransgenderOutlined style={{color:'black'}} />
                {
                  datos.trans ? (
                    <CheckCircle
                    style={{
                      position:'absolute',
                      right:-5,
                      bottom:-5,
                      color:'#00BA00'
                    }}
                    />
                  ):null
                }
              </Button>
              <Button
                variant='contained'
                size='small'
                onClick={() => {actualizaDatos('telefono', !datos.telefono)}}

                style={{
                  backgroundColor:'white',
                  maxHeight:'80%',
               //   marginLeft:20
                }}
              >
                <Phone style={{color:'black'}} />
                {
                  datos.telefono ? (
                    <CheckCircle
                    style={{
                      position:'absolute',
                      right:-5,
                      bottom:-5,
                      color:'#00BA00'
                    }}
                    />
                  ):null
                }
              </Button>
              <Button
                onClick={() => {obtenerBeneficiarios()}}
                size='small'
                style={{
                  textTransform:'unset',
                  backgroundColor:'white',
                  color:'red',
                  borderRadius:20,
               //   marginLeft:20
                }}
              >
                Filtrar
              </Button>
              {usuario?.permisos?.substr(10,1)==1 ? (

              <Button
                onClick={() => {exportar()}}
                size='small'
                style={{
                  textTransform:'unset',
                  backgroundColor:'white',
                  color:'red',
                  borderRadius:20,
                //  marginLeft:20
                }}
              >
                Exportar
              </Button>
              ):null
            }
              <Typography
                style={{
                  fontSize:20,
                  color:'white',
                  fontWeight:'600',
                //  marginLeft:60,
                  marginRight:20
                }}
              >
                Filtros
              </Typography>
      </div>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                flex:1
              }}
            >
              <div
                style={{
                  display:'flex',
                  flexDirection:'column',
                  flex:60,
                  backgroundColor:'white',
                  borderBottomLeftRadius:20,
                  borderBottomRightRadius:20
                }}
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    flex:1,
                    fontSize:15,
                    fontWeight:'600',
                    padding:20,
                    maxHeight:20
                  }}
                >
                  Beneficiarios
                </div>
                <Divider 
                  style={{
                   // borderColor:'gray'
                  }}
                />
              <div
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    flex:1,
                    fontSize:12,
                    fontWeight:'300',
                    padding:20,
                    maxHeight:20,
                    alignItems:'center',
                    justifyContent:'space-between'
                  }}
              >
                <div>
                  Total  {beneficiarios?.total}
                </div>
                {usuario?.permisos?.substr(6,1)==1 ? (
                <div>
                  <Button
                    onClick={() => {setBeneficiarioSeleccionado(); setModalAgregaBeneficiario(true)}}
                    size='small'
                    variant='contained'
                    style={{
                      textTransform:'unset',
                      backgroundColor:'white',
                      color:'black'

                    }}
                  >
                    Agregar
                  </Button>
                </div>
                ):null
              }
              </div>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:1,
                  //backgroundColor:'#F7F7F7',
                  //backgroundColor:'red',
                  maxHeight:15,
                  padding:20,
                  alignItems:'center',
                  fontSize:12,
                //  maxHeight:'60vh',
                  overflowY:'auto'
                }}
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:10,
                    fontWeight:'500'
                  }}
                >
                  <div
                      onClick={() => {if(ordenBeneficiarios?.includes('aPaterno')) {setOrdenBeneficiarios('idRubASC')}}}
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      alignItems:'center',
                      cursor:'pointer'
                    }}
                  >
                  Id RUB
                  {
                    ordenBeneficiarios=='idRubASC' ? (
                      <IconButton
                      onClick={() => {console.log('idrub descentente'); setOrdenBeneficiarios('idRubDESC')}}
                        size='small'
                      >
                        <ArrowDropUp />
                      </IconButton>
                    ):null
                  }
                  {
                    ordenBeneficiarios=='idRubDESC' ? (
                      <IconButton
                      onClick={() => {setOrdenBeneficiarios('idRubASC')}}
                      size='small'
                      >
                      <ArrowDropDown />
                      </IconButton>
                    ):null
                  }
                  </div>
                </div>
                <div
                      style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500'
                  }}
                >
                  <div
                      onClick={() => {if(ordenBeneficiarios?.includes('idRub')) {setOrdenBeneficiarios('aPaternoASC')}}}
                      style={{
                      display:'flex',
                      flexDirection:'row',
                      alignItems:'center',
                      cursor:'pointer'
                    }}
                  >
                  Apellido Paterno
                  {
                    ordenBeneficiarios=='aPaternoASC' ? (
                      <IconButton
                      onClick={() => {setOrdenBeneficiarios('aPaternoDESC')}}
                        size='small'
                      >
                        <ArrowDropUp />
                      </IconButton>
                    ):null
                  }
                  {
                    ordenBeneficiarios=='aPaternoDESC' ? (
                      <IconButton
                      onClick={() => {setOrdenBeneficiarios('aPaternoASC')}}
                      size='small'
                      >
                      <ArrowDropDown />
                      </IconButton>
                    ):null
                  }
                  </div>
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500'
                  }}
                >
                  Apellido Materno
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500'
                  }}
                >
                  Nombre 1
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500'
                  }}
                >
                  Nombre 2
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500',
                    alignItems:'flex-end',
                    marginRight:20
                  }}
                >
                  No. de Apoyos
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:10,
                    fontWeight:'500'
                  }}
                >
                  Sector
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:5,
                    fontWeight:'500'
                  }}
                >
                   
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:5,
                    fontWeight:'500'
                  }}
                >
                   
                </div>
              </div>
              <div
                style={{
//                  backgroundColor:'red',
                  maxHeight:'55vh',
                  overflowY:'auto',
                  borderRadius:20,
                  minHeight:'55vh'
                }}
              >
              {
                beneficiarios?.beneficiarios?.map((beneficiario, index) =>(
              <div
                  onClick={() => {setBeneficiarioSeleccionado(beneficiario)}}
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:1,
                  backgroundColor: beneficiarioSeleccionado?.idBeneficiario == beneficiario?.idBeneficiario ? '#B3282D':'white',
                  color: beneficiarioSeleccionado?.idBeneficiario == beneficiario?.idBeneficiario ? 'white':'black',
                  maxHeight:25,
                  padding:10,
                  paddingLeft:20,
                  paddingRight:20,
                  alignItems:'center',
                  fontSize:12,
                  fontWeight:'300',
                  cursor:'pointer'
                }}
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:10,
                  }}
                >
                  {beneficiario?.idBeneficiario}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {beneficiario?.apellidoPaterno}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {beneficiario?.apellidoMaterno}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {beneficiario?.nombre1}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {beneficiario?.nombre2}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    alignItems:'flex-end',
                    marginRight:20
                  }}
                >
                  {beneficiario?.apoyos}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:10,
                  }}
                >
                  Sector
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:5,
                  }}
                >
                                    {
                    (usuario?.permisos).substr(12,1)=='1' ? (    

                   <IconButton
                    onClick={() => {setBeneficiarioSeleccionado(beneficiario);editar(beneficiario) }}
                    size='small'
                    sx={{ width: 20, height: 20, borderRadius: 1 }}
                    style={{
                      backgroundColor:'#00BA00',
                    }}
                   >
                    <Edit 
                      style={{
                        fontSize:14
                      }}
                    />
                   </IconButton>
                    ):null
                  }
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:5,
                  }}
                >
                  {
                    (usuario?.permisos).substr(11,1)=='1' ? (    
                   <IconButton
                    onClick={() => {eliminarBeneficiario(beneficiarioSeleccionado?.idBeneficiario)}}
                    size='small'
                    sx={{ width: 20, height: 20, borderRadius: 1 }}
                    style={{
                      backgroundColor:'#F06666',
                    }}
                   >
                    <Close
                      style={{
                        fontSize:14
                      }}
                    />
                   </IconButton>
                    ):null
                  }
                </div>
              </div>
                ))}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    flex:1,
                    backgroundColor:'transparent',
                    justifyContent:'flex-end',
                    marginRight:50
                  }}
                >
                  <Pagination count={(beneficiarios?.total/10).toFixed(0)} showFirstButton showLastButton onChange={cambioPaginador} />
                </div>
                </div>
              <div
                style={{
                  display:'flex',
                  flexDirection:'column',
                  flex:30,
                  backgroundColor:'transparent',
                  borderBottomLeftRadius:20,
                  borderBottomRightRadius:20,
                  marginLeft:20,
                  marginRight:20
                }}
              >
                <div
                  style={{
                    backgroundColor:'white',
                    padding:0,
                    display:'flex',
                    flexDirection:'column',
                    flex:1,
                    marginTop:10,
                    borderRadius:15
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:'center',
                      marginTop:20
                    }}
                  >
                    {
                      beneficiarioSeleccionado?.foto ? (

                    <img 
                          src={beneficiarioSeleccionado?.foto} alt="Image from remote URL" 
                          style={{
                              width:80,
                              height:'auto',
                              marginLeft:40,
                              borderRadius:50,
                              
                          }}
                      />
                      ):null
                    }

                      <div
                        style={{
                          backgroundColor:'transparent',
                          justifyContent:'center',
                          alignItems:'center',
                          display:'flex',
                          flexDirection:'row'
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:'transparent',
                            maxWidth:180,
                            backgroundColor:'transparent',
                            position:'relative'
                          }}
                        >
                        <IconButton
                        style={{
                        textTransform:'unset',
                        position:'absolute',
                 //       maxWidth:140,
                        //minWidth:'40%',
                        bottom:10,
                        right:10,
                        backgroundColor:'transparent'
                        }}
                        variant="contained"
                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                        ><ImageSearch /></IconButton>
                        {
                          !beneficiarioSeleccionado?.foto ? (
                            <Person style={{fontSize:120, color:'lightgray'}} />

                          ):null
                        }

                          <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpeg,.jpg,.png' type="file"  />

                        </div>

                      </div>
                    {/* <IconButton
                      onClick={() => {subirFoto()}}
                    >
                      <CameraAlt 
                        style={{
                          top:-5,
                          right:-5
                        }}
                      />
                    </IconButton> */}
                  </div>
                  {
                    beneficiarioSeleccionado?.nombre1 ? (
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      fontWeight:'600',
                      justifyContent:'center',
                      flex:1,
                      maxHeight:20,
                      marginTop:10,
                      fontWeight:'600'
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight:'600'
                      }}
                    >
                      {beneficiarioSeleccionado?.nombre1+' '+beneficiarioSeleccionado?.nombre2+' '+beneficiarioSeleccionado?.apellidoPaterno+' '+beneficiarioSeleccionado?.apellidoMaterno}
                    </Typography>
                  </div>
                    ):null
                  }
                  {
                    dayjs(beneficiarioSeleccionado?.fechaNacimiento).isValid() ? (
                      
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginRight:0,
                      backgroundColor:'#F9FAFF',
                      paddingTop:10,
                      paddingBottom:10

                    }}
                    >
                    <AccountCircle
                      style={{
                        marginRight:30,
                        color:'#00BA00',
                        marginLeft:20
                        
                      }}
                    />
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column'
                      }}
                    >
                    <Typography>
                      {dayjs(beneficiarioSeleccionado?.fechaNacimiento).locale('es').format('DD [de] MMMM YYYY')}
                    </Typography>
                    <Typography
                      style={{
                        fontSize:12,
                        color:'#00BA00'
                      }}
                    >
                      {dayjs().diff(beneficiarioSeleccionado?.fechaNacimiento,'year')} años
                    </Typography>
                    </div>
                  </div>
                    ):null
                  }
                 <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginRight:0,
                      backgroundColor:'white',
                      paddingTop:0,
                      paddingBottom:10

                    }}
                    >
                    <Room
                      style={{
                        marginRight:30,
                        marginLeft:20,
                        color:'#00BA00'
                        
                      }}
                    />
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column'
                      }}
                    >
                      {
                        beneficiarioSeleccionado?.direccion ? (

                          <Typography>
                      {beneficiarioSeleccionado?.direccion+' '+beneficiarioSeleccionado?.colonia}
                    </Typography>
                      ):(
                        <Typography>
                          No capturada
                        </Typography>
                      )
                    }
                    </div>
                  </div>     
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginRight:0,
                      backgroundColor:'#F9FAFF',
                      paddingTop:10,
                      paddingBottom:10

                    }}
                    >
                    <Phone
                      style={{
                        marginRight:30,
                        color:'#00BA00',
                        marginLeft:20
                        
                      }}
                    />
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column'
                      }}
                    >
                    <Typography>
                      {beneficiarioSeleccionado?.telefono}
                    </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginRight:0,
                      backgroundColor:'white',
                      paddingTop:10,
                      paddingBottom:10

                    }}
                    >
                      <div 
                        onClick={() => {setModalApoyosBrindados(true)}}
                        style={{
                          backgroundColor:'#00BA00',
                          marginLeft:20,
                          marginRight:30,
                          borderRadius:50,
                          width:15,
                          height:15,
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          padding:5,
                          color:'white',
                          fontSize:11,
                          cursor:'pointer'
                          
                        }}
                      >
                        {beneficiarioSeleccionado?.apoyos}
                      </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column'
                      }}
                    >
                    <Typography style={{color:'black'}}>
                      Apoyos brindados
                    </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:'center',
                      marginTop:20
                    }}
                  >
                    {
                      beneficiarioSeleccionado?.idAnverso ? (

                    <img 
                          src={beneficiarioSeleccionado?.idAnverso} alt="Image from remote URL" 
                          style={{
                              width:'auto',
                              height:70,
                              marginLeft:40,
                              borderRadius:10,
                             // transform: `rotate(${90}deg)`,

                          }}
                      />
                      ):null
                    }

                      <div
                        style={{
                          backgroundColor:'transparent',
                          justifyContent:'center',
                          alignItems:'center',
                          display:'flex',
                          flexDirection:'row'
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:'transparent',
                            maxWidth:180,
                            backgroundColor:'transparent',
                            position:'relative'
                          }}
                        >
                        <IconButton
                        style={{
                        textTransform:'unset',
                        position:'absolute',
                 //       maxWidth:140,
                        //minWidth:'40%',
                        bottom:10,
                        right:10,
                        backgroundColor:'transparent'
                        }}
                        variant="contained"
                        onClick={() => uploadInputRef1.current && uploadInputRef1.current.click()}
                        ><ImageSearch /></IconButton>
                        {
                          !beneficiarioSeleccionado?.idAnverso ? (
                            <Person style={{fontSize:30, color:'lightgray'}} />

                          ):null
                        }

                          <input name="imgMilitante" onChange={event => SeleccionImagen1(event) } ref={uploadInputRef1} style={{visibility:'hidden'}} accept='.jpeg,.jpg,.png' type="file"  />

                        </div>

                      </div>
                    {/* <IconButton
                      onClick={() => {subirFoto()}}
                    >
                      <CameraAlt 
                        style={{
                          top:-5,
                          right:-5
                        }}
                      />
                    </IconButton> */}
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:'center',
                      marginTop:20
                    }}
                  >
                    {
                      beneficiarioSeleccionado?.idReverso ? (

                    <img 
                          src={beneficiarioSeleccionado?.idReverso} alt="Image from remote URL" 
                          style={{
                            width:'auto',
                            height:70,
                            marginLeft:40,
                              borderRadius:10,
                          //    transform: `rotate(${-90}deg)`,
                              
                          }}
                      />
                      ):null
                    }

                      <div
                        style={{
                          backgroundColor:'transparent',
                          justifyContent:'center',
                          alignItems:'center',
                          display:'flex',
                          flexDirection:'row'
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:'transparent',
                            maxWidth:180,
                            backgroundColor:'transparent',
                            position:'relative'
                          }}
                        >
                        <IconButton
                        style={{
                        textTransform:'unset',
                        position:'absolute',
                 //       maxWidth:140,
                        //minWidth:'40%',
                        bottom:10,
                        right:10,
                        backgroundColor:'transparent'
                        }}
                        variant="contained"
                        onClick={() => uploadInputRef2.current && uploadInputRef2.current.click()}
                        ><ImageSearch /></IconButton>
                        {
                          !beneficiarioSeleccionado?.idReverso ? (
                            <Person style={{fontSize:120, color:'lightgray'}} />

                          ):null
                        }

                          <input name="imgMilitante" onChange={event => SeleccionImagen2(event) } ref={uploadInputRef2} style={{visibility:'hidden'}} accept='.jpeg,.jpg,.png' type="file"  />

                        </div>

                      </div>
                    {/* <IconButton
                      onClick={() => {subirFoto()}}
                    >
                      <CameraAlt 
                        style={{
                          top:-5,
                          right:-5
                        }}
                      />
                    </IconButton> */}
                  </div>
                  
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginLeft:20,
                      marginRight:20,
                      justifyContent:'space-between'
                    }}
                  >
                     {usuario?.permisos?.substr(7,1)==1 ? (
                    <Button
                      onClick={() => {
                        setModalEntregaApoyo(true)
                      }}
                      variant='contained'
                      style={{
                        textTransform:'unset',
                        backgroundColor:'white',
                        color:'gray',
                        borderRadius:10

                      }}
                    >
                      Entrega de Apoyo
                    </Button>
                     ):null
                    }
                   {usuario?.permisos?.substr(9,1)==1 ? (
                    <Button
                      onClick={() => {setModalFichaRub(true)}}
                      variant='contained'
                      style={{
                        textTransform:'unset',
                        backgroundColor:'white',
                        color:'gray',
                        borderRadius:10
                      }}
                    >
                      Ficha RUB
                    </Button>
                   ):null
                  }
                  </div>

                </div>
              </div>
              </div>
      </div>
      </div>
      <ModalAgregarBeneficiario obtenerBeneficiarios={obtenerBeneficiarios} />
      <ModalEntregaApoyo />
      <ModalApoyosBrindados />
      <ModalFichaRub />
      </>
  )
}
