import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { ObtenerDatos } from '../helpers/ApiHelper'
import dayjs from 'dayjs'
import { Camera, CameraAlt, Delete, Photo } from '@mui/icons-material'
import { ModalImagenesEvidencia } from './ModalImagenesEvidencia'
import Swal from 'sweetalert2'
import './estilo.css'; // Import your CSS file

export const ModalApoyosBrindados = () => {
    const {modalApoyosBrindados, setModalApoyosBrindados, beneficiarioSeleccionado, setModalImagenesEvidencia, usuario} = useContext(AuthContext)
    const [apoyos, setApoyos] = useState()
const [apoyoSeleccionado, setApoyoSeleccionado] = useState()

    const handleClose = () => {
        setModalApoyosBrindados(false)
    }

    const obtenerApoyos = () => {
        const data = new FormData();
        data.append('idBeneficiario', beneficiarioSeleccionado?.idBeneficiario)
        ObtenerDatos('/apoyos.php?funcion=apoyosBeneficiario', data).then(response => {
            console.log('Respuesta de apoyos', response)
            setApoyos(response)
        })
    }

    useEffect(() => {
        if(modalApoyosBrindados) obtenerApoyos()
    }, [modalApoyosBrindados])
    
    const eliminarApoyo = (idApoyo) => {
        console.log('eliminando apoyo')
        const data = new FormData()
        data.append('idApoyo', idApoyo)
        Swal.fire({
            title: "Eiminar apoyo",
            text: "Esto no podrá ser revertido!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            customClass: {
                popup: 'custom-swal', // Apply the custom class to the popup
              },
          }).then((result) => {
            if (result.isConfirmed) {

            ObtenerDatos('/apoyos.php?funcion=eliminarApoyo', data).then(response => {
                console.log('Respuesta de apoyos', response)
//                setApoyos(response)
                    obtenerApoyos()
                    Swal.fire({
                        title: "Eliminado!",
                        text: "El apoyo fue eliminado correctamente",
                        icon: "success"
                    });

            })


            }
          });
    }


  return (
    <Dialog
        size="lg"
        maxWidth="lg"
        fullWidth
        open={modalApoyosBrindados}
        onClose={handleClose}
    >
        <DialogContent>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:1
                }}
            >
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        backgroundColor:'#B3282D',
                        paddingBottom:20,
                        paddingTop:20,
                        padding:20,
                        borderTopLeftRadius:15,
                        borderTopRightRadius:15
                    }}
                >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Fecha
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Autorizó
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Apoyo
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Comentarios
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Evidencia
                    </div>
                    <div
                        style={{
                            display:'flex',
                            justifyContent:'flex-end',
                            alignItems:'flex-end',
                            flexDirection:'column',
                            flex:5
                        }}
                    >
                        
                    </div>
                </div>
                {
                    apoyos?.map((apoyo, index) => (

                <div
                    key={index}
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        backgroundColor:'white',
                        paddingBottom:20,
                        paddingTop:20,
                        padding:20,
                        fontSize:12
                    }}
                >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        {dayjs(apoyo.fecha).format('DD/MM/YYYY')}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        {apoyo?.nombreCompleto}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        {apoyo?.tipoApoyo}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        {apoyo?.comentarios}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20,
                            alignItems:'center'
                        }}
                    >
                        <IconButton
                            onClick={() => {setApoyoSeleccionado(apoyo); setModalImagenesEvidencia(true)}}
                        >
                        <CameraAlt
                            style={{
                                color:apoyo?.evidencia.length>0 ? '#00BA00':'black'
                            }}
                        />
                        </IconButton>
                    </div>
                    {usuario?.permisos?.substr(13,1)==1 ? (
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:5,
                            alignItems:'center'
                        }}
                    >
                        <IconButton
                            onClick={() => {setApoyoSeleccionado(apoyo); eliminarApoyo(apoyo?.idApoyo)}}
                        >
                        <Delete
                            style={{
                                color:'red'
                            }}
                        />
                        </IconButton>
                    </div>
                    ):null
                }
                </div>
                ))
            }
                
            </div>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
                style={{
                    textTransform:'unset'
                }}
            >
                Cerrar
            </Button>
        </DialogActions>
        <ModalImagenesEvidencia apoyoSeleccionado={apoyoSeleccionado}  />
    </Dialog>
  )
}
