import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { ObtenerDatos } from '../helpers/ApiHelper'
import { ObtenerBeneficiarios } from '../helpers/BeneficiariosHelper'
import useGeneros from './GenerosComponent'
import Swal from 'sweetalert2';
import dayjs from 'dayjs'
import { CheckCircle, ImageSearch } from '@mui/icons-material'

export const ModalAgregarBeneficiario = ({obtenerBeneficiarios}) => {
    const {modalAgregaBeneficiario, setModalAgregaBeneficiario, beneficiarioSeleccionado, usuario} = useContext(AuthContext)
    const {generoSeleccionado, setGeneroSeleccionado, ComboGeneros} = useGeneros()
    const [deshabilitado, setDeshabilitado] = useState(true)
    const [datosBeneficiario, setDatosBeneficiario] = useState({
        'nombre1':'',
        'nombre2':'',
        'apellidoPaterno':'',
        'apellidoMaterno':'',
        'idGenero1':0,
        'fechaNacimiento':'',
        'direccion':'',
        'colonia':'',
        'telefono':'',
        'curp':''

    })
        const uploadInputRef = useRef(null);
        const [file, setFile] = useState()
        const uploadInputRef1 = useRef(null);
        const [file1, setFile1] = useState()
        const uploadInputRef2 = useRef(null);
        const [file2, setFile2] = useState()
    

        const SeleccionImagen = (event) => {
            setFile(event.target.files);
            console.log('selecciono imagen',event);
            var reader = new FileReader();
    //        reader.onload = function(){
    //            imgMilitante.current.src = reader.result;
     //       };
     //       reader.readAsDataURL(event.target.files[0]);
        };

    useEffect(() => {
        if(beneficiarioSeleccionado?.accion=='editar') {
            console.log('editando', beneficiarioSeleccionado)
            setDatosBeneficiario({
                'nombre1':beneficiarioSeleccionado?.nombre1,
                'nombre2':beneficiarioSeleccionado?.nombre2,
                'apellidoPaterno':beneficiarioSeleccionado?.apellidoPaterno,
                'apellidoMaterno':beneficiarioSeleccionado?.apellidoMaterno,
                'idGenero1':beneficiarioSeleccionado?.idGenero,
                'fechaNacimiento':beneficiarioSeleccionado?.fechaNacimiento,
                'direccion':beneficiarioSeleccionado?.direccion,
                'colonia':beneficiarioSeleccionado?.colonia,
                'telefono':beneficiarioSeleccionado?.telefono,
                'idBeneficiario':beneficiarioSeleccionado?.idBeneficiario,
                'curp':beneficiarioSeleccionado?.curp
        
            })
            setGeneroSeleccionado({value:beneficiarioSeleccionado.idGenero, label:beneficiarioSeleccionado?.genero})
        } else {
            console.log('registro nuevo', beneficiarioSeleccionado)
            setDatosBeneficiario({
            'nombre1':'',
            'nombre2':'',
            'apellidoPaterno':'',
            'apellidoMaterno':'',
            'idGenero1':0,
            'fechaNacimiento':'',
            'direccion':'',
            'colonia':'',
            'telefono':'',
            curp:''
            })
        }
    }, [modalAgregaBeneficiario, beneficiarioSeleccionado])


    useEffect(() => {
      
        console.log(uploadInputRef)

    }, [uploadInputRef])
    



    const handleClose =() => {
        setGeneroSeleccionado();
        setModalAgregaBeneficiario(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        console.log('habilito submit');
        const data = new FormData();
        data.append('datos', JSON.stringify(datosBeneficiario))
        data.append('archivos', uploadInputRef.current?.files?.length || 0)
        data.append('token', usuario?.token)

        for(let x=0;x<uploadInputRef?.current?.files.length;x++) {
            console.log('frente', uploadInputRef.current?.files[x])
            data.append('frente', uploadInputRef.current?.files[0]);
        }
        for(let x=0;x<uploadInputRef1?.current?.files.length;x++) {
            console.log('reverso', uploadInputRef1.current?.files[x])
            data.append('reverso', uploadInputRef1.current?.files[0]);
        }
        for(let x=0;x<uploadInputRef2?.current?.files.length;x++) {
            console.log('beneficiario', uploadInputRef2.current?.files[x])
            data.append('beneficiario', uploadInputRef2.current?.files[0]);
        }
        ObtenerDatos('/beneficiarios.php?funcion=agregarBeneficiario', data).then(response => {
            console.log('respuesta del servidor', response);
            setModalAgregaBeneficiario(false);
            setGeneroSeleccionado()
            obtenerBeneficiarios();
            if(response==0) {
                console.log('No se agrego porque ya existe el RFC')
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: "La CURP ya está registrada con el id RUB: "+response?.idBeneficiario+', '+response?.nombre1+' '+response?.apellidoPaterno,
                    showConfirmButton: true,
                    //timer: 1500
                  });

            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Beneficiario guardado correctamente, FOLIO: "+response?.idBeneficiario,
                    showConfirmButton: true,
                    //timer: 1500
                  });

            }
        })

    }

    const actualizaDatos = (campo, valor) => {
        setDatosBeneficiario({
            ...datosBeneficiario,
            [campo]:valor
        })
    }

    useEffect(() => {
        console.log('datos de beneficiario', datosBeneficiario)
        validaFecha(datosBeneficiario?.fechaNacimiento)
    }, [datosBeneficiario])

    useEffect(() => {
        actualizaDatos('idGenero1', generoSeleccionado?.value)
    }, [generoSeleccionado])

    useEffect(() => {
        console.log('beneficiario en modal a editar', beneficiarioSeleccionado)
    }, [beneficiarioSeleccionado])
    
    
    const validaFecha = (fecha) => {
        console.log('fecha a validar', fecha)
        const diferencia = dayjs().diff(dayjs(fecha), 'year');
        console.log('diferencia años', diferencia, datosBeneficiario)
        if((diferencia>=18 && diferencia<=100) && datosBeneficiario?.apellidoMaterno?.length>0 && datosBeneficiario?.apellidoPaterno?.length>0 && datosBeneficiario?.colonia?.length>0 && datosBeneficiario?.curp?.length>0 && datosBeneficiario?.direccion?.length>0 && datosBeneficiario?.idGenero1>0 && datosBeneficiario?.telefono?.length>0) {
            setDeshabilitado(false)
        } else {
            
            setDeshabilitado(true)

        }
    }

  return (
    <Dialog
        open={modalAgregaBeneficiario}
        onClose={handleClose}
        size='md'
        fullWidth
    >
        <form
            onSubmit={handleSubmit}
        >
        <DialogContent>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column'
                }}
            >
            <TextField 
                onChange={(e) => {actualizaDatos('nombre1', e.target.value)}}
                value={datosBeneficiario?.nombre1}
                size='small'
                label="Nombre 1"
                type='text'
                required
                InputLabelProps={{
                    shrink:true,
                }}
           />
            <TextField 
                onChange={(e) => {actualizaDatos('nombre2', e.target.value)}}
                value={datosBeneficiario?.nombre2}
                size='small'
                label="Nombre 2"
                type='text'
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <TextField 
                onChange={(e) => {actualizaDatos('apellidoPaterno', e.target.value)}}
                value={datosBeneficiario?.apellidoPaterno}
                size='small'
                label="Apellido paterno"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField 
                onChange={(e) => {actualizaDatos('apellidoMaterno', e.target.value)}}
                value={datosBeneficiario?.apellidoMaterno}
                size='small'
                label="Apellido materno"
                type='text'
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <div
                style={{
                    marginTop:5
                }}
            >
                <ComboGeneros />

            </div>
            <TextField 
                onChange={(e) => {actualizaDatos('fechaNacimiento', e.target.value); validaFecha(e.target.value)}}
                value={datosBeneficiario?.fechaNacimiento}

                size='small'
                label="Fecha de nacimiento"
                type='date'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField 
                onChange={(e) => {actualizaDatos('curp', e.target.value)}}
                value={datosBeneficiario?.curp}

                size='small'
                label="CURP"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField 
                value={datosBeneficiario?.direccion}
                onChange={(e) => {actualizaDatos('direccion', e.target.value)}}
                size='small'
                label="Dirección"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <TextField 
                onChange={(e) => {actualizaDatos('colonia', e.target.value)}}

                size='small'
                label="Colonia"
                type='text'
                required
                value={datosBeneficiario?.colonia}
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <TextField 
                onChange={(e) => {actualizaDatos('telefono', e.target.value)}}
                size='small'
                label="Teléfono"
                type='number'
                required
                value={datosBeneficiario?.telefono}
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
              
            />
                            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='image/*' type="file"  />
                            <Button

                                style={{
                                textTransform:'unset',
                                //maxWidth:140,
                                minWidth:'80%',
                                marginTop:0,
                                backgroundColor:uploadInputRef?.current?.files?.length>0 ? 'green':'#B3282D'
                                }}
                                variant="contained"
                                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                >Frente Identificación <ImageSearch />
                                {
                                    uploadInputRef?.current?.files?.length>0 ? (
                                <CheckCircle />
                                    ):null
                                }
                                </Button>
                            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef1} style={{visibility:'hidden'}} accept='image/*' type="file" capture="camera" />
            
                            <Button
                                style={{
                                textTransform:'unset',
                                //maxWidth:140,
                                minWidth:'80%',
                                marginTop:0,
                                backgroundColor:uploadInputRef1?.current?.files?.length>0 ? 'green':'#B3282D'
                                }}
                                variant="contained"
                                onClick={() => uploadInputRef1.current && uploadInputRef1.current.click()}
                                >Reverso Identificación<ImageSearch />
                                {
                                    uploadInputRef1?.current?.files?.length>0 ? (
                                <CheckCircle />
                                    ):null
                                }
                                </Button>
                            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef2} style={{visibility:'hidden'}} accept='image/*' type="file" capture="camera" />
                            <Button
                                style={{
                                textTransform:'unset',
                                //maxWidth:140,
                                minWidth:'40%',
                                marginTop:0,
                                backgroundColor:uploadInputRef2?.current?.files?.length>0 ? 'green':'#B3282D'
                                }}
                                variant="contained"
                                onClick={() => uploadInputRef2.current && uploadInputRef2.current.click()}
                                >Beneficiario <ImageSearch />
                                {
                                    uploadInputRef?.current?.files?.length>0 ? (
                                <CheckCircle />
                                    ):null
                                }
                                
                                </Button>
            
            </div>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {setModalAgregaBeneficiario(false)}}
                style={{
                    textTransform:'unset',
                    color:'black'
                }}
            >
                Cancelar
            </Button>
            <Button
                disabled={deshabilitado}
                type='submit'
                style={{
                    textTransform:'unset',
                    color:deshabilitado ? 'gray':'black'
                }}
            >
                Guardar
            </Button>
        </DialogActions>
        </form>
    </Dialog>
  )
}
