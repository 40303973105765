import { CheckCircle, ImageAspectRatio, Man2Outlined, MinorCrashTwoTone, Phone, PhotoCamera, Search, TransgenderOutlined, Woman2Outlined } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, Pagination, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ObtenerDatos } from '../helpers/ApiHelper'
import logoEncabezado from '../assets/logoEncabezado.png'
import { UsuarioComponent } from '../components/UsuarioComponent'
//import useDependencias from '../components/DependenciasComponent'
import useTiposApoyo from '../hooks/useTiposApoyo'
import useGeneros from '../components/GenerosComponent'
import { AuthContext } from '../context/AuthContext'
import { ObtenerBeneficiarios } from '../helpers/BeneficiariosHelper'
import useDependenciasUsuario from '../components/DependenciasUsuario'
import dayjs from 'dayjs'
import { ModalImagenesEvidencia } from '../components/ModalImagenesEvidencia'


export const ApoyosPage = () => {
    const [busqueda, setBusqueda] = useState()
    const [beneficiarios, setBeneficiarios] = useState()
    const [inicio, setInicio] = useState(dayjs().startOf('month').format('YYYY-MM-DD'))
    const [fin, setFin] = useState(dayjs().format('YYYY-MM-DD'))
    const [beneficiarioSeleccionado, setBeneficiarioSeleccionado] = useState()
    const [datos, setDatos] = useState()
    const {usuario} = useContext(AuthContext)
    const [paginaBeneficiarios, setPaginaBeneficiarios] = useState(1)
    const [ordenBeneficiarios, setOrdenBeneficiarios] = useState()
    const [apoyos, setApoyos] = useState()
    const [apoyoSeleccionado, setApoyoSeleccionado] = useState()
    const [colonias, setColonias] = useState()
    const [paginaApoyos, setPaginaApoyos] = useState(1)
    const {dependencias, ComboDependencias, dependenciaSeleccionada} = useDependenciasUsuario();
    const {tipoApoyoSeleccionado, ComboTiposApoyo, obtenerTiposApoyo} = useTiposApoyo();
    const {generoSeleccionado, setGeneroSeleccionado, ComboGeneros} = useGeneros()
    const {modalImagenesEvidencia, setModalImagenesEvidencia} = useContext(AuthContext)

  
    useEffect(() => {
        if(apoyos?.length>0) {
           console.log('colonias', agruparColonias())
        }
    }, [apoyos])
    
    useEffect(() => {
        console.log('Colonias', colonias)
    }, [colonias])

    const cambioPaginador = (e, value) => {
      console.log('cambio en paginador', e, value)
      setPaginaApoyos(value)
    }
    

    const agruparColonias = () => {
    const agrupadosPorColonia =  apoyos?.reduce((acc, apoyo) => {
        console.log('apoyo en reduce', apoyo?.colonia)
        // Verificamos si la colonia ya existe en el acumulador
        if (!acc[apoyo?.colonia]) {
          // Si no existe, la inicializamos como un arreglo vacío
          acc[apoyo?.colonia] = [];
        }
        // Agregamos el objeto actual al arreglo correspondiente
        acc[apoyo.colonia].push(apoyo);
       return acc;
      }, {});
      return agrupadosPorColonia;
    }

    const buscarBeneficiario =() => {
      const data = new FormData();
      data.append('busqueda', busqueda)
      ObtenerDatos('/beneficiarios.php?funcion=buscarBeneficiario', data).then(response => {
        console.log('resultados de la busqueda', response)
        setBeneficiarios(response)
      })
    }

    const actualizaDatos = async (campo, valor) => {
        console.log(campo, valor)
        await setDatos({
          ...datos,
          [campo]:valor
        })
        await setBeneficiarioSeleccionado({
          ...beneficiarioSeleccionado,
          'accion':'editar'
        })
        if(valor=='editar') {
//          await setModalAgregaBeneficiario(true);
        } 
      }

          const obtenerBeneficiarios = () => {
            if(usuario?.permisos?.substr(8,1)==1) {
      
            ObtenerBeneficiarios(datos, paginaBeneficiarios, ordenBeneficiarios).then(response => {
              console.log('beneficiarios obtenidos',response, paginaBeneficiarios)
              
              setBeneficiarios(response)
            })
          }
          }

            const exportar = () => {
                console.log('apoyos a exportar', beneficiarios?.beneficiarios)
                let data = new FormData();
                data.append('inicio', inicio)
                data.append('fin', fin)
                data.append('idDependencia', dependenciaSeleccionada?.value)
                data.append('idTipoApoyo', tipoApoyoSeleccionado?.value || 0)
                data.append('token', usuario?.token)
                const params = new URLSearchParams(data);
                window.open('https://rubjrz.com/servicios/exportar.apoyos.php?'+params)
          
          
              }

              useEffect(() => {
                console.log('cambio la pagina, obteniendo beneficiarios', paginaBeneficiarios)
                obtenerApoyos();
              }, [paginaApoyos])

              const obtenerApoyos = () => {
                console.log('datos', datos)
                const data = new FormData()
                data.append('idDependencia', dependenciaSeleccionada?.value)
                data.append('idTipoApoyo', tipoApoyoSeleccionado?.value || 0)
                data.append('inicio', inicio)
                data.append('fin', fin)
                data.append('pagina', paginaApoyos)
                ObtenerDatos('/apoyos.php?funcion=obtenerApoyos', data).then(response => {
                    console.log('respuesta de apoyos', response)
                    setApoyos(response)
                }).catch(error => {
                    console.error(error)
                })
              }

                useEffect(() => {
                  console.log('selecciono dependencia', dependenciaSeleccionada)
                  obtenerTiposApoyo(dependenciaSeleccionada?.value || 0);
                }, [dependenciaSeleccionada])
              
                useEffect(() => {
                    console.log('datos', datos)
                }, [datos])
                
          
      

  return (
    <>
    <div
    style={{
      display:'flex',
      flex:1,
      flexDirection:'column',
      marginLeft:20
    }}
  >
    <div
        style={{
          display:'flex',
          flexDirection:'row',
          flex:10,
          justifyContent:'space-between',
          marginTop:20,
          marginRight:20, maxHeight:32,
          alignItems:'center'
        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center'
          }}
        >
     {/* <TextField
      label="Buscar beneficiario por Nombres, #RUB ó CURP"
      onChange={(e) => {setBusqueda(e.target.value)}}
      InputProps={{
        shrink:true,
        style:{
          backgroundColor:'white',
          borderRadius:10,
          fontSize:12,
          minWidth:500,
          maxHeight:35
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
    <Button
      onClick={() => {buscarBeneficiario()}}
      variant='contained'
      style={{
        backgroundColor:'#B3282D',
        textTransform:'unset',
        paddingLeft:40,
        paddingRight:40,
        maxHeight:30,
        borderRadius:15,
        marginLeft:30
      }}
    >
      <Typography>
        Buscar
      </Typography>
    </Button> */}
    </div>
        <div
          style={{
            display:'flex',
            flexDirection:'row'
            
          }}
        >
        <img
            style={{
                width:250,
                height:'auto',
                
//                marginTop:'15%'
            }}
            src={logoEncabezado}
        />
        <UsuarioComponent /> 
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'column',
          flex:1,
          backgroundColor:'transparent',
          marginLeft:0,
          marginTop:20,
          marginBottom:20,
          borderRadius:15    
        }}
      >
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:20,
          backgroundColor:'#B3282D',
          paddingTop:5,
          paddingBottom:5,
          paddingLeft:20,
          borderTopLeftRadius:15,
          borderTopRightRadius:15,
          marginRight:20,
          alignItems:'center',
          justifyContent:'space-between'
        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            justifyContent:'space-between',
            backgroundColor:'transparent'
            //maxWidth:160

          }}
        >
            <div
                stye={{
                   // minWidth:700,
                     display:'flex',
                     flexDirection:'column',
                     flex:90,
                     backgroundColor:'red', marginRight:200
                }}
            >
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    flex:1,
                    marginRight:200
                }}
            >
      <ComboDependencias
        style={{
          width:100
        }}
      />
     
        <div
          style={{
            maxWidth:90,
         //   marginLeft:10
          }}
        >
      <ComboTiposApoyo />
      </div>
      <div
              style={{
                backgroundColor:'transparent',
                minWidth:120,
                marginLeft:70,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                alignItems:'center'
              }}
            >
              <Typography
              style={{
                color:'white',
                fontSize:12
              }}
            >
              De
            </Typography>
               <TextField
                 type='date'
                 size='small'
                 onChange={(e) => {setInicio(e.target.value)}}
                 value={inicio}
                 inputProps={{
                   style:{
                     fontSize:12
                   }
                 }}
                 style={{
                   backgroundColor:'white',
                   borderRadius:15,
                   marginLeft:10,
                   width:140
                 }}
               />
             </div>
             <div
               style={{
                 backgroundColor:'transparent',
                 minWidth:150,
                 marginLeft:20,
                 alignItems:'center',
                 display:'flex',
                 flexDirection:'row'
               }}
             ><Typography
               style={{
                 color:'white',
                 fontSize:12
               }}
             >
               Hasta
             </Typography>
               <TextField
            //     value={dayjs(fin).format('YYYY-MM-DD')}
                 type='date'
                 size='small'
                 onChange={(e) => {setFin(e.target.value)}}
                 value={fin}
                 inputProps={{
                   style: {
                     fontSize:11
 
                   }
                 }}
                 style={{
                   backgroundColor:'white',
                   borderRadius:15,
                  // marginLeft:10,
                   width:140,
                   fontSize:12
                 }}
               />
               </div>                
              </div>      
              </div>
              <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:10,
                    justifyContent:'center'
                }}
              >
                            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'flex-end'
                }}
            >

              <Button
                onClick={() => {obtenerApoyos()}}
                size='small'
                style={{
                  textTransform:'unset',
                  backgroundColor:'white',
                  color:'red',
                  borderRadius:20,
                  marginRight:10
               //   marginLeft:20
                }}
              >
                Filtrar
              </Button>
              {usuario?.permisos?.substr(10,1)==1 ? (

              <Button
                onClick={() => {exportar()}}
                size='small'
                style={{
                  textTransform:'unset',
                  backgroundColor:'white',
                  color:'red',
                  borderRadius:20,
                  marginRight:30
                //  marginLeft:20
                }}
              >
                Exportar
              </Button>
              ):null
            }
              <Typography
                style={{
                  fontSize:20,
                  color:'white',
                  fontWeight:'600',
                //  marginLeft:60,
                  marginRight:20
                }}
              >
                Filtros
              </Typography>
              </div>
              </div>
              </div>

      </div>
      <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            backgroundColor:'transparent',
            marginRight:20
        }}  
      >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex: 80,
                backgroundColor:'white',
                borderBottomLeftRadius:20,
                borderBottomRightRadius:20,
                overflowY:'auto'
            }}
        >
                         <div
                              style={{
                                display:'flex',
                                flexDirection:'row',
                                flex:1,
                                fontSize:12,
                                fontWeight:'300',
                                padding:20,
                                maxHeight:20,
                                alignItems:'center',
                                justifyContent:'space-between'
                              }}
                          >
                            <div>
                              Total  {apoyos?.length}
                            </div>
                          </div>
                          <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:1,
                  //backgroundColor:'#F7F7F7',
                  //backgroundColor:'red',
                  maxHeight:15,
                  padding:20,
                  alignItems:'center',
                  fontSize:12,
                //  maxHeight:'60vh',
                  overflowY:'auto',
                  marginRight:20
                }}
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:8,
                    fontWeight:'500'
                  }}
                >
                  Fecha
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500'
                  }}
                >
                  Dependencia
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500',
               //     marginLeft:0
                  }}
                >
                  Apoyo
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500'
                  }}
                >
                  Beneficiario
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500'
                  }}
                >
                  Dirección
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500'
                  }}
                >
                  Colonia
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                    fontWeight:'500'
                  }}
                >
                  Entregó
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:3,
                    fontWeight:'500'
                  }}
                >
                  
                </div>
            </div>
            <div
                style={{
                    overflowY:'auto',
                    maxHeight:'55vh',
                    diplay:'flex',
                    flex:1
                }}
            >
            {
            apoyos?.apoyos?.map((apoyo, index) =>(
              <div
                  onClick={() => {setApoyoSeleccionado(apoyo)}}
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:1,
                  backgroundColor: apoyoSeleccionado?.idBeneficiario == apoyo?.idBeneficiario ? '#B3282D':'white',
                  color: apoyoSeleccionado?.idBeneficiario == apoyo?.idBeneficiario ? 'white':'black',
                  maxHeight:25,
                  padding:20,
                  paddingLeft:20,
                  paddingRight:20,

                  alignItems:'center',
                  fontSize:11,
                  fontWeight:'300',
                  cursor:'pointer'
                }}
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:8,
                  }}
                >
                  {dayjs(apoyo?.fecha).format('DD/MM/YYYY')}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {apoyo?.dependencia}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {apoyo?.tipoApoyo}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {apoyo?.nombre1+' '+apoyo?.nombre2+' '+apoyo?.apellidoPaterno+' '+apoyo?.apellidoMaterno}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {apoyo?.direccion}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {apoyo?.colonia}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:17,
                  }}
                >
                  {apoyo?.nombreCompleto}
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:3,
                  }}
                >
                  <IconButton
                    onClick={() => {setModalImagenesEvidencia(true)}}
                  >
                    <PhotoCamera 
                      style={{
                        color: apoyo?.evidencia?.length>0 ? 'green':'black'
                      }}
                    />
                  </IconButton>
                </div>
                </div>
            ))}
 
            </div>

        </div>
        {/* <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex: 15,
                marginLeft:20,
                backgroundColor:'red'
            }}
        >
            Imagen
        </div> */}
      </div>
      <div
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    flex:1,
                    backgroundColor:'transparent',
                    justifyContent:'flex-end',
                    marginRight:50
                  }}
                >
                  <Pagination count={(apoyos?.total/100).toFixed(0)} showFirstButton showLastButton onChange={cambioPaginador} />
                </div>

    </div>
    </div>
    <ModalImagenesEvidencia apoyoSeleccionado={apoyoSeleccionado}  />
    </>
  )
}
