import React, { useContext } from 'react'
import { MenuComponent } from '../components/MenuComponent'
import { AuthContext } from '../context/AuthContext'
import { DashboardPage } from './DashboardPage'
import { BeneficiariosPage } from './BeneficiariosPage'
import { CiudadPage } from './CiudadPage'
import { UsuariosPage } from './UsuariosPage'
import { VerificacionPage } from './VerificacionPage'
import { ResumenPage } from './ResumenPage'
import { ApoyosPage } from './ApoyosPage'

export const PrincipalPage = () => {
    const {opcionSeleccionada} = useContext(AuthContext)
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            backgroundColor:'#ebebec',
            height:'100vh'
            //minHeight:'100%'
        }}
    >
        <div
            style={{
                diplay:'flex',
                flexDirection:'column',
                flex:15
            }}
        >
            <MenuComponent />
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:85
            }}
        >
            {
                opcionSeleccionada=='dashboard' ? (
                    <DashboardPage />
                ):null

            }
            {
                opcionSeleccionada=='beneficiarios' ? (
                    <BeneficiariosPage />
                ):null

            }
            {
                opcionSeleccionada=='apoyos' ? (
                    <ApoyosPage />
                ):null

            }
            {
                opcionSeleccionada=='ciudad' ? (
                    <CiudadPage />
                ):null

            }
            {
                opcionSeleccionada=='usuarios' ? (
                    <UsuariosPage />
                ):null

            }
            {
                opcionSeleccionada=='verificacion' ? (
                    <VerificacionPage />
                ):null

            }
            {
                opcionSeleccionada=='resumen' ? (
                    <ResumenPage />
                ):null

            }
        </div>
    </div>
  )
}
