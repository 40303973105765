import { ObtenerDatos } from "./ApiHelper"

export const ObtenerDependencias = () => {
    return new Promise((resolve, reject) => {

        const data = new FormData();
       // data.append('orden','TipoInstalacion asc')
         fetch(process.env.REACT_APP_API_URL+'/dependencias.php?funcion=obtenerDependencias', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })

}

export const ObtenerDependenciasUsuario = (idUsuario, suDependencia) => {
    return new Promise((resolve, reject) => {

        const data = new FormData();
        data.append('suDependencia', suDependencia ? 1:0)
       // data.append('orden','TipoInstalacion asc')
         fetch(process.env.REACT_APP_API_URL+'/dependencias.php?funcion=obtenerDependenciasUsuario&idUsuario='+idUsuario, {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })

}