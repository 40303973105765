import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { ObtenerTiposApoyo } from '../helpers/TiposApoyoHelper';

const useTiposApoyo = () => {
    const [tiposApoyo, setTiposApoyo] = useState()
    const [tipoApoyoSeleccionado, setTipoApoyoSeleccionado] = useState()

    const obtenerTiposApoyo = (idDependencia) => {
        setTipoApoyoSeleccionado();
        ObtenerTiposApoyo(idDependencia).then(response => {
            let opciones = new Array();
            opciones.push({value:'%', label:'Todos'})
            response?.map(apoyo => {
                opciones.push({value:apoyo.idTipoApoyo,label:apoyo.tipoApoyo})
            })

            setTiposApoyo(opciones)
            console.log('tipos de apoyo en componente',response)
        });
    }

    useEffect(() => {
        //obtenerTiposApoyo();
    }, [])

    useEffect(() => {

    }, [tipoApoyoSeleccionado])
    
    
    const ComboTiposApoyo = () => {
        return (
            <div>
                <Select
                    onChange={(e) => {setTipoApoyoSeleccionado(e)}}
                    value={tipoApoyoSeleccionado}
                    options={tiposApoyo}
                    placeholder="Apoyo"
                    menuPortalTarget={document.body} 
                    InputLabelProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    InputProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize:11 }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'grey' : 'green',
                            fontSize:12,
                            minWidth:150,
                            maxHeight:20,
                            borderRadius:10
                        }),
                    }}
                    style={{
                        width:150,
                        maxHeight:20,
                        menuPortal: base => ({ ...base, zIndex: 999999, fontSize:11 }),
        
                    }}
                />
    </div>
  )
    }
  return {tiposApoyo, ComboTiposApoyo, obtenerTiposApoyo, tipoApoyoSeleccionado, setTipoApoyoSeleccionado}
};

export default useTiposApoyo

